import React from 'react'

import './card.scss'

export default function Card({ className, children, onClick }) {

    const El = onClick ? 'button' : 'div'

    return (
        <El className={`card ${ className }`} onClick={ onClick }>
            { children }
        </El>
    )
}