import React, { useState } from 'react'

import Title from 'components/Title'
import { PrimaryButton } from 'components/Button'

import './pricing.scss'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

import Container from 'components/Container'
import ExternalLink from '../components/ExternalLink'
import Card from 'components/Card'
import Footer from "components/Footer"
import Header from "components/Header"
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
export default () => {

    const { t } = useTranslation()

    const { title } = useSiteMetadata()

    const MONTHLY_FEE = 59.95

    return (
        <>
        <Header/>
        <main className='main-content' style={{ textAlign: 'center' }}>
            <Title 
                dark 
                title={ t('Special pricing for a limited time') } 
                subtitle={ t(`Try {{ siteTitle }} free for 30 days, no credit card required`, { siteTitle: title }) }
            />

            <div className='priceWrapper'>
                <Price 
                    amount={MONTHLY_FEE}
                    benefits={[
                        t('Unlimited messages'),
                        t('Unlimited customers'),
                        t('Unlimited team members'),
                        t('iOS, Android, Mac and Windows')
                    ]}
                />
            </div>

            <Container>

                <div className='priceTerms'>

                    <Term
                        title={ t('Security is free') }
                        icon='lock'
                    >
                        { t(`Your data is encrypted in transit and at rest. Data transfer is automatically secured with end-to-end SSL Encryption (HTTPS). PCI DSS Level 1 compliant payment processing.`) }
                    </Term>

                    <Term
                        title={ t('No contracts') }
                        icon='receipt'
                    >
                        { t(`{{ siteTitle }} is a recurring subscription service. You may upgrade, downgrade or cancel at any time. No questions asked.`, { siteTitle: title }) }
                    </Term>

                    <Term
                        title={ t('30 day guarantee') }
                        icon='verified_user'
                    >
                        { t(`If you are not totally satisfied with {{ siteTitle }} and cancel your account within 30 days of your payment, we will issue a full refund.`, { siteTitle: title }) }
                    </Term>

                    <Term
                        title={ t('Auto-renewal terms') }
                        icon='autorenew'
                    >
                        { t(`{{ siteTitle }} is a subscription service and will continue until you cancel. It auto-renews at the end of each term and you will be charged for the subsequent term.`, { siteTitle: title }) }
                    </Term>

                </div>

                <h3 className='questions'>{ t(`Questions? We have answers.`) }</h3>

                <Question question={ t(`How much does it cost to use {{ siteTitle }}?`, { siteTitle: title }) }>
                    <p>
                        { t(`With {{ siteTitle }} there are no contracts, no up-front fees, no PCI or security fees, no deposit or transfer fees, no cancellation fees, and no hidden fees of any kind.`, { siteTitle: title }) }
                    </p>
                    <p>
                        { t(`{{ siteTitle }} fees are as follows:`, { siteTitle: title }) }
                    </p>
                    <p>
                        <strong>{ t(`Monthly fee:`) }</strong> ${ MONTHLY_FEE } +{ t('tax', `TAX`) }
                    </p>
                    <p>
                        <strong>{ t(`Online and mobile payment transactions:`) }</strong> <Trans>Our third-party payment processor <ExternalLink href='https://stripe.com'>Stripe</ExternalLink> charges a fixed rate of <strong>2.9% + $0.30</strong> per successful transaction.<br/>The rate is the same for all credit card types.</Trans>
                    </p>
                    <p>
                        <strong>{ t(`In-person credit / debit transactions:`) }</strong> { t(`{{ siteTitle }} does not charge any fees for transactions processed on your existing payment terminals. Transaction fees are subject to your merchant agreement.`, { siteTitle: title }) }
                    </p>
                </Question>

                <Question question={ t(`What does it cost to accept online or mobile payments?`) }>
                    <p>
                        <Trans>Our third-party payment processor <ExternalLink href='https://stripe.com'>Stripe</ExternalLink> charges a fixed rate of <strong>2.9% + $0.30</strong> per successful transaction.</Trans>
                    </p>
                    <p>
                        { t(`The rate is the same for all credit card types.`) }
                    </p>
                    <p>
                        <Trans><strong>Example:</strong> $100 payment = $96.80 deposited into your bank account.</Trans>
                    </p>
                </Question>

                <Question question={ t(`Does it cost more to accept premium credit cards online?`) }>
                    <p>
                        <Trans>All credit cards are charged at the same rate of <strong>2.9% + $0.30</strong> per successfull transaction.</Trans>
                    </p>
                </Question>

                {/* <Question question={`Why should I offer online or mobile payments to my customers?`}>
                    <p>
                        
                    </p>
                </Question> */}

                <Question question={t(`Is {{ siteTitle }} secure?`, { siteTitle: title })}>
                    <p>
                        <Trans>All data transfer is encrypted and sent over secure HTTPS connections. All credit / debit card information complies with PCI DSS Level 1 and is handled by our third-party payment processor <ExternalLink href='https://stripe.com'>Stripe</ExternalLink>.</Trans>
                    </p>
                </Question>
            
            </Container>

        </main>
        <Footer/>
        </>
    )
}

function Price({ amount, benefits }) {

    const { t } = useTranslation()

    const { appUrl } = useSiteMetadata()

    const amountString = amount.toString().split('.')

    return (
        <div className='priceWrapper'>
            
            <div className='priceContainer'>
                <div>
                    <div className='price'>
                        ${ amountString[0] }<sm>.{ amountString[1] }/mo</sm>
                        {/* ${ amountString[0] }<sm>/mo</sm> */}
                    </div>
                    {/* <div className='pricePer'>/month</div> */}
                    <div className='priceDisclaimer'>{ t(`No setup cost`) }</div>

                    <div className='onlinePaymentContainer'>
                        <div className='onlinePaymentTitle'>{ t(`Online and mobile payments*`) }</div>
                        <div className='onlinePaymentRate'>{ t(`2.9% + $0.30`) }</div>
                        <div className='onlinePaymentDisclaimer'>per transaction</div>
                        <div className='onlinePaymentOptional'>{ t(`*Accept online and mobile payments through Stripe`) }</div>
                    </div>
                </div>
            </div>
            
            <div className='priceFeatures'>
                <div>
                    {/* <h3>A customer loyalty solution with everything you need</h3>  */}
                    <ul className='priceBenefits'>
                        {
                            benefits.map((benefit, i) => <li key={i}><i className='material-icons'>done</i>{ benefit }</li>)
                        }
                    </ul>
                    <PrimaryButton className='priceSignUp' href={ appUrl + '/sign-up' } style={{ lineHeight: '24px' }}>{ t(`Sign up free`) }<i style={{ verticalAlign: 'bottom', marginLeft: '1rem' }} className='material-icons'>arrow_forward</i></PrimaryButton>
                    <sm>{ t(`No credit card required`) }</sm>
                </div>
            </div>

        </div>
    )
}

function Term({ icon, title, children }) {

    return (
        <div className='termContainer'>
            <h3><i className='material-icons'>{ icon }</i>{ title }</h3>
            <p>{ children }</p>
        </div>
    )        
}

function Question({ question, children }) {

    const { t } = useTranslation()

    const [ open, setOpen ] = useState(false)

    return (
        <Card className='questionWrapper' onClick={ () => setOpen(!open) }>
            <div className='questionContainer'>
                <div className='question'>{ question }</div>
                <div className='questionButton'>{ t(`See Answer`) }<i className='material-icons'>expand_more</i></div>
            </div>
            <div className={`answer ${ open ? 'answer--open' : '' }`}>
                { children }
            </div>
        </Card>
    )

}